import React from "react";
import { Link, graphql } from "gatsby";
import Img from "gatsby-image";
import { MARKS, BLOCKS } from "@contentful/rich-text-types";
import { renderRichText } from "gatsby-source-contentful/rich-text";

import Layout from "../components/layout";
import Seo from "../components/seo";

const ServicesTemplate = ({ data, pageContext: context }) => {
  const { title, main_title, subtitle } = data.contentfulPage;
  const allPages = data.allContentfulPage.nodes;

  function getParentSlug() {
    return allPages.find((page) => page.category === "Services").slug;
  }

  return (
    <Layout>
      <Seo title={title} description={main_title} />
      <section className="section section-white">
        <div className="introduction introduction-clouds">
          <div className="introduction-container">
            <div className="introduction-inner">
              <div className="container">
                <div className="row justify-content-center">
                  <div className="col-xl-10">
                    <p className="eyebrow">{title}</p>
                    <h1>{main_title}</h1>
                    <p className="lead">{subtitle}</p>
                    <hr />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="section section-gradient">
        <div className="container">
          {data.allContentfulService.nodes.map((node, i) => (
            <div className="columns" key={i}>
              <div className="columns-item columns-item-image">
                <Img
                  fluid={{ ...node.thumbnail.fluid, aspectRatio: 460 / 352 }}
                  alt={node.title}
                />
              </div>
              <div className="columns-item columns-item-text">
                {renderRichText(node.main_title, {
                  renderNode: {
                    [BLOCKS.PARAGRAPH]: (node, children) => <h2>{children}</h2>,
                  },
                  renderMark: {
                    [MARKS.BOLD]: (text) => <mark>{text}</mark>,
                  },
                })}
                {renderRichText(node.short_description)}
                <p className="mt-md-8">
                  <Link
                    to={`/${context.locale}/${getParentSlug()}/${node.slug}/`}
                  >
                    {node.title}
                  </Link>
                </p>
              </div>
            </div>
          ))}
        </div>
      </section>
    </Layout>
  );
};

export default ServicesTemplate;

export const query = graphql`
  query ($id: String!, $slug: String!, $locale: String!) {
    allSitePage(filter: { context: { id: { eq: $id } } }) {
      nodes {
        path
        context {
          locale
        }
      }
    }
    allContentfulPage(filter: { node_locale: { eq: $locale } }) {
      nodes {
        contentful_id
        title
        slug
        category
      }
    }
    contentfulHotel(
      contentful_id: { eq: "7Ghq3MGGxup1gZhcPpUnH" }
      node_locale: { eq: $locale }
    ) {
      meta_description
      hero_image {
        file {
          url
        }
      }
    }

    contentfulPage(slug: { eq: $slug }, node_locale: { eq: $locale }) {
      title
      main_title
      subtitle
    }
    allContentfulService(
      filter: { node_locale: { eq: $locale } }
      sort: { fields: [title], order: ASC }
    ) {
      nodes {
        title
        main_title {
          raw
        }
        short_description {
          raw
        }
        slug
        thumbnail {
          fluid(maxWidth: 460, quality: 80) {
            ...GatsbyContentfulFluid_withWebp
          }
        }
      }
    }
  }
`;
